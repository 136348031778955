import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {Form, Formik, Field, yupToFormErrors, FieldArray} from "formik";
import * as Yup from "yup";
import {changeOverlayContent} from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";
import * as DocumentActions from "actions/Document";
import "./ItemLightbox.scss";
import "./BlocContactLightbox.scss";

const BlocContactLightbox = (props) => {
    const dispatch = useDispatch();
    const {
        blocContact,
        mode,
        folderId
    } = props;
    const [touched, setTouched] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const decideHeader = () => {
        let header = "";
        if (mode === "create") {
            header = "Créer ";
        } else if (mode === "update") {
            header = "Modifier ";
        } else {
            return "";
        }

        header += "un bloc";

        return header;
    };

    const handleSubmit = (values) => {
        let method = "";
        if (mode === "create") {
            values.folder = folderId
            method = DocumentActions.createBlocContact(JSON.stringify(values));
        } else if (mode === "update") {
            method = DocumentActions.updateBlocContact(
                blocContact.id,
                JSON.stringify(values)
            );
        }
        dispatch(method).then(() => {
            changeOverlayContent(null);
            dispatch(DocumentActions.fetchSocialDocuments());
        });
    };

    const schema = Yup.object().shape({
        title: Yup.string(),
        url: Yup.string(),
        urlLabel: Yup.string(),
        address: Yup.string(),
        names: Yup.array().of(Yup.string()).nullable(),
        phones: Yup.array().of(Yup.string()).nullable(),
        mails: Yup.array().of(Yup.string()).nullable(),
    });

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{textTransform: "uppercase"}}
                >
                    {decideHeader()}
                </div>
                <Formik
                    initialValues={
                        {
                            title: mode === "update" ? (blocContact.title || '') : "",
                            url: mode === "update" ? (blocContact.url || '') : "",
                            urlLabel: mode === "update" ? (blocContact.urlLabel || '') : "",
                            address: mode === "update" ? (blocContact.address || '') : "",
                            names: mode === "update" ? (blocContact.names || ['']) : [""],
                            phones: mode === "update" ? (blocContact.phones || ['']) : [""],
                            mails: mode === "update" ? (blocContact.mails || ['']) : [""],
                        }
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values, props) => {
                        return schema
                            .validate(values, {abortEarly: false})
                            .then(() => {
                                return true;
                            })
                            .catch((err) => {
                                console.log(err);
                                return yupToFormErrors(err);
                            });
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        handleSubmit(values);
                    }}
                >
                    {({errors, isSubmitting, values}) => (
                        <Form className="lb-item-documents">
                            <div className="lb-item-row">
                                <label htmlFor="label">Nom du bloc</label>
                                <Field
                                    className={errors?.label ? "error" : ""}
                                    type="text"
                                    id="title"
                                    name="title"
                                />

                                {errors?.label && (
                                    <div className="error-warning">
                                        {errors.label}
                                    </div>
                                )}
                            </div>
                            <div className="lb-item-row">
                                <label htmlFor="label">Adresse</label>
                                <Field
                                    className={errors?.address ? "error" : ""}
                                    type="text"
                                    id="address"
                                    name="address"
                                />

                                {errors?.address && (
                                    <div className="error-warning">
                                        {errors.address}
                                    </div>
                                )}
                            </div>
                            <div className="lb-item-row">
                                <label htmlFor="url">Url</label>
                                <Field
                                    className={errors?.url ? "error" : ""}
                                    type="text"
                                    id="url"
                                    name="url"
                                />

                                {errors?.url && (
                                    <div className="error-warning">
                                        {errors.url}
                                    </div>
                                )}
                            </div>
                            <div className="lb-item-row">
                                <label htmlFor="urlLabel">Texte à afficher pour le lien</label>
                                <Field
                                    className={errors?.urlLabel ? "error" : ""}
                                    type="text"
                                    id="urlLabel"
                                    name="urlLabel"
                                />

                                {errors?.urlLabel && (
                                    <div className="error-warning">
                                        {errors.urlLabel}
                                    </div>
                                )}
                            </div>
                            <div className="contact-informations-container">
                                <div className="name-column">
                                    <span className="column-header">Noms</span>
                                    <FieldArray name="names"
                                                render={arrayHelpers => (<>
                                                    {values.names && Object.values(values.names).map((name, key) => (
                                                        <div className="lb-item-row" key={key}>
                                                            <Field
                                                                className={errors?.names ? "error" : ""}
                                                                type="text"
                                                                id={`names[${key}]`}
                                                                name={`names[${key}]`}
                                                            />

                                                            {errors?.names && (
                                                                <div className="error-warning">
                                                                    {errors.names}
                                                                </div>
                                                            )}
                                                            <span className="column-remove-row"
                                                                  onClick={() => arrayHelpers.remove(key)}>
                                                                <i className="far fa-minus-circle"></i>
                                                            </span>
                                                        </div>
                                                    ))}
                                                    <div className="column-add-row"
                                                         onClick={() => arrayHelpers.push('')}>
                                                        <i className="far fa-plus-circle"></i>
                                                    </div>
                                                </>)}
                                    />
                                </div>

                                <div className="phone-column">
                                    <span className="column-header">Téléphones</span>
                                    <FieldArray name="phones"
                                                render={arrayHelpers => (<>
                                                    {values.phones && Object.values(values.phones).map((phone, key) => (
                                                        <div className="lb-item-row" key={key}>
                                                            <Field
                                                                className={errors?.phones ? "error" : ""}
                                                                type="text"
                                                                id={`phones[${key}]`}
                                                                name={`phones[${key}]`}
                                                                value={values.phones[key] || ''}
                                                            />

                                                            {errors?.phones && (
                                                                <div className="error-warning">
                                                                    {errors.phones}
                                                                </div>
                                                            )}
                                                            <span className="column-remove-row"
                                                                  onClick={() => arrayHelpers.remove(key)}>
                                                                <i className="far fa-minus-circle"></i>
                                                            </span>
                                                        </div>
                                                    ))}
                                                    <div className="column-add-row"
                                                         onClick={() => arrayHelpers.push('')}>
                                                        <i className="far fa-plus-circle"></i>
                                                    </div>
                                                </>)}
                                    />

                                </div>
                                <div className="mail-column">
                                    <span className="column-header">Emails</span>
                                    <FieldArray name="mails"
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.mails && Object.values(values.mails).map((mail, key) => (
                                                            <div className="lb-item-row" key={key}>
                                                                <Field
                                                                    className={errors?.mails ? "error" : ""}
                                                                    type="text"
                                                                    id={`mails[${key}]`}
                                                                    name={`mails[${key}]`}
                                                                    value={values.mails[key] || ''}
                                                                />

                                                                {errors?.mails && (
                                                                    <div className="error-warning">
                                                                        {errors.mails}
                                                                    </div>
                                                                )}
                                                                <span className="column-remove-row"
                                                                      onClick={() => arrayHelpers.remove(key)}>
                                                                    <i className="far fa-minus-circle"></i>
                                                                </span>
                                                            </div>
                                                        ))}
                                                        <div className="column-add-row"
                                                             onClick={() => arrayHelpers.push('')}>
                                                            <i className="far fa-plus-circle"></i>
                                                        </div>
                                                    </>)}/>


                                </div>
                            </div>


                            <div className="lb-confirm-send-actions">
                                <div
                                    onClick={() => changeOverlayContent(null)}
                                    className="lb-confirm-send-cancel"
                                >
                                    Annuler
                                </div>
                                <button
                                    type="submit"
                                    className={
                                        "lb-confirm-send-confirm" +
                                        (submitting ? " disabled-btn" : "")
                                    }
                                >
                                    Envoyer
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Lightbox>
    );
};

export default BlocContactLightbox;
