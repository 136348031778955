import React, {useEffect, useState, useRef} from "react";

const BlocRow = (props) => {

    const {
        categoryId,
        item,
        visible,
        nested,
        isEdit,
        isInFolder,
        type,
        unionId,
    } = props;
    const [hovered, setHovered] = useState(false);
    console.log(item);

    return (
        <div
            elementid={item?.id}
            className={
                "social-item-container movable-item " +
                (!visible ? " hidden" : "") +
                (nested ? " nested" : "")
            }
            title={item?.label}
        >
            <div className="ssct-item">
            <div className="ssct-title">{item.title}</div>
            <div className="ssct-contact">
                <div className="ssct-persons">
                    {item.names.map((person, key) => {
                        return <div key={key}>{person}</div>;
                    })}
                </div>
                <div className="separator">
                    <div className="triangle"></div>
                </div>
                <div className="ssct-emails">
                    <i className="fal fa-envelope-open"></i>
                    <div className="ssct-emails-content">
                        {item.mails.map((email, key) => {
                            return (
                                <a href={`mailto:${email}`} key={key}>
                                    {email}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
            {/*<div className="insurance-item">*/}
            {/*    <div className="label-item">{item.label}</div>*/}
            {/*    <div className="insurance-contact">*/}
            {/*        <div className="phone-item">*/}
            {/*            <i className="fal fa-phone"></i>*/}
            {/*            {file.phone}*/}
            {/*        </div>*/}
            {/*        <div className="email-content">*/}
            {/*            <i className="fal fa-envelope-open"></i>*/}
            {/*            <a className="email-item" href={`mailto:${file.email}`}>*/}
            {/*                {file.email}*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="url-item">*/}
            {/*        <div className="url-picto">*/}
            {/*            <i className="fal fa-globe"></i>*/}
            {/*        </div>*/}
            {/*        <div className="url-content">*/}
            {/*            <div className="url-content-label">*/}
            {/*                Espace assuré :{" "}*/}
            {/*            </div>*/}
            {/*            <a href={file.url} target="_blank">*/}
            {/*                {file.url}*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="insurance-address">*/}
            {/*        <i className="fal fa-map-marker"></i>*/}
            {/*        {file.address}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default BlocRow;
