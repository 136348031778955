import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Formik, Field, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { changeOverlayContent } from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";
import * as DocumentActions from "actions/Document";
import "./ItemLightbox.scss";

const ItemLightbox = (props) => {
    const dispatch = useDispatch();
    const {
        sectionId,
        categoryId,
        folderId,
        fileId,
        mode,
        type,
        itemLabel,
        unionId,
    } = props;
    const [touched, setTouched] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const decideHeader = () => {
        let header = "";
        if (mode === "create") {
            header = "Créer ";
            if (sectionId) {
                header += "une catégorie";
            } else if (categoryId) {
                header += "un dossier";
            } else {
                return "";
            }
        } else if (mode === "update") {
            header = "Modifier ";
            if (categoryId) {
                header += "une catégorie";
            } else if (folderId) {
                header += "un dossier";
            } else if (fileId) {
                header += "un fichier";
            } else {
                return "";
            }
        } else {
            return "";
        }

        return header;
    };

    const decideLabel = () => {
        let label = "";

        if (mode === "create") {
            if (sectionId) {
                label = "de la catégorie";
            } else if (categoryId) {
                label = "du dossier";
            }
        } else if (mode === "update") {
            if (categoryId) {
                label = "de la catégorie";
            } else if (folderId) {
                label = "du dossier";
            } else if (fileId) {
                label = "du fichier";
            } else {
                return "";
            }
        } else {
            return "";
        }

        return label;
    };

    const handleSubmit = (values) => {
        let method = "";
        if (mode === "create") {
            if (sectionId) {
                method = DocumentActions.createCategory(JSON.stringify(values));
            } else if (categoryId) {
                method = DocumentActions.createFolder(JSON.stringify(values));
            }
        } else if (mode === "update") {
            if (categoryId) {
                method = DocumentActions.updateCategory(
                    categoryId,
                    JSON.stringify(values)
                );
            } else if (folderId) {
                method = DocumentActions.updateFolder(
                    folderId,
                    JSON.stringify(values)
                );
            } else if (fileId) {
                method = DocumentActions.updateFileLabel(
                    fileId,
                    JSON.stringify(values),
                    type
                );
            }
        }
        dispatch(method).then(() => {
            changeOverlayContent(null);
            if (unionId) {
                dispatch(DocumentActions.fetchUnion(unionId));
            }
            dispatch(DocumentActions.fetchDocuments());
            dispatch(DocumentActions.fetchSocialDocuments());
            dispatch(DocumentActions.fetchUnions());
        });
    };

    const schema = Yup.object().shape({
        label: Yup.string().min(
            1,
            `Vous devez renseigner un nom pour votre ${
                mode === "create" && sectionId
                    ? "catégorie"
                    : categoryId
                    ? "document"
                    : "dossier"
            }`
        ),
    });

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{ textTransform: "uppercase" }}
                >
                    {decideHeader()}
                </div>
                <Formik
                    initialValues={
                        sectionId
                            ? {
                                  section: sectionId,
                                  label: mode === "update" ? itemLabel : "",
                              }
                            : categoryId
                            ? {
                                  category: categoryId,
                                  label: mode === "update" ? itemLabel : "",
                              }
                            : {
                                  label: mode === "update" ? itemLabel : "",
                              }
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values, props) => {
                        return schema
                            .validate(values, { abortEarly: false })
                            .then(() => {
                                return true;
                            })
                            .catch((err) => {
                                return yupToFormErrors(err);
                            });
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                    }}
                >
                    {({ errors, isSubmitting }) => (
                        <Form className="lb-item-documents">
                            <div className="lb-item-row">
                                <label htmlFor="label">
                                    {"Nom " + decideLabel()}
                                </label>
                                <Field
                                    className={errors?.label ? "error" : ""}
                                    type="text"
                                    id="label"
                                    name="label"
                                />

                                {errors?.label && (
                                    <div className="error-warning">
                                        {errors.label}
                                    </div>
                                )}
                            </div>
                            <div className="lb-confirm-send-actions">
                                <div
                                    onClick={() => changeOverlayContent(null)}
                                    className="lb-confirm-send-cancel"
                                >
                                    Annuler
                                </div>
                                <button
                                    type="submit"
                                    className={
                                        "lb-confirm-send-confirm" +
                                        (submitting ? " disabled-btn" : "")
                                    }
                                >
                                    Envoyer
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Lightbox>
    );
};

export default ItemLightbox;
