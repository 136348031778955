import React from "react";
import DocumentsList from "components/DocumentContainer/DocumentsList";
import SocialList from "../../DocumentContainer/SocialList";

const DocumentContainer = (props) => {
    const {isSocial} = props;
    return (
        <div id="page-container" className="backend">
            <div className="container">
                {isSocial ? <SocialList isEdit={true}/> : <DocumentsList isEdit={true}/>}

            </div>
        </div>
    );
};

export default DocumentContainer;
