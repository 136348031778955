import Contact from "./Contact";

class Assistant {
    contact;
    firstname;
    lastname;
    title;

    constructor(json) {
        this.contact = new Contact(json.contact);
        this.firstname = json.firstname;
        this.lastname = json.lastname;
        this.title = json.title;
    }
}

export default Assistant;
