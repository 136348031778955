import React, { useState, useEffect } from "react";
import Dropdown from "components/common/Dropdown";
import { changeOverlayContent } from "events/OverlayEvents";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import SocialCategory from "components/DocumentContainer/Social/SocialCategory";
import "./Section.scss";

const SocialSection = (props) => {
    const { section, isEdit, type } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="section-container">
            <div className="section-title">
                <div className="section-title-label">{section.label}</div>
            </div>
            {isEdit && (
                    <Dropdown
                        isDropdownVisible={isDropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                    >
                        <ul className="dropdown-list">
                            <li
                                onClick={() => {
                                    setDropdownVisible(false);
                                    changeOverlayContent(
                                        <ItemLightbox
                                            sectionId={section.id}
                                            mode="create"
                                        />
                                    );
                                }}
                            >
                                Ajouter une catégorie
                            </li>
                        </ul>
                    </Dropdown>
                )}
            {section.categories?.map((category, key) => {
                return (
                    <SocialCategory key={key} category={category} type={type} isEdit={isEdit}/>
                );
            })}
        </div>
    );
};

export default SocialSection;
