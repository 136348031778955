import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SocialSection from "components/DocumentContainer/SocialSection";
import Section from "components/DocumentContainer/Section";
import Sortable from "sortablejs";
import LinkItem from "entities/Social/LinkItem";
import * as DocumentActions from "actions/Document";

const SocialList = (props) => {
    const {isEdit} = props;
    const dispatch = useDispatch();

    const socialDocuments = useSelector(
        (state) => state.document.entities.socialDocuments
    );

    const unionInfos = useSelector(
        (state) => state.document.entities.unionsArray
    );

    const socialInfos = useSelector(
        (state) => state.document.entities.socialInfos
    );

    const cseInfos = useSelector((state) => state.document.entities.cse);

    const generateUnionSection = () => {
        let linkItems = [];
        Object.values(unionInfos).map((union) => {
            linkItems.push(new LinkItem(union));
        });
        return {
            label: "Communications syndicales",
            categories: [
                {
                    label: "Syndicats",
                    folders: [
                        {
                            label: "Les différents syndicats",
                            items: linkItems,
                        },
                    ],
                },
            ],
        };
    };

    const [generatedUnionSection, setGeneratedUnionSection] = useState(
        generateUnionSection()
    );

    const moveBloc = (blocId, newPosition) => {
        let json = {
            id: blocId,
            type: "bloc",
            position: newPosition,
        };
        dispatch(DocumentActions.moveElement(JSON.stringify(json)));
    };

    const moveFile = (fileId, newPosition) => {
        let json = {
            id: fileId,
            type: "file",
            position: newPosition,
        };
        dispatch(DocumentActions.moveElement(JSON.stringify(json)));
    };

    const moveFolder = (folderId, newPosition) => {
        let json = {
            id: folderId,
            type: "folder",
            position: newPosition,
        };
        dispatch(DocumentActions.moveElement(JSON.stringify(json)));
    };

    useEffect(() => {
        dispatch(DocumentActions.fetchSocialDocuments()).then(() => {
            if (isEdit) {
                let containers = document.querySelectorAll(
                    ".category-container"
                );
                Object.values(containers).map((element, index) => {
                    new Sortable(element, {
                        animation: 150,
                        draggable: '.folder-container',
                        ghostClass: "ghost",
                        fallbackOnBody: true,
                        swapThreshold: 0.65,
                        onEnd: (event) => {
                            let itemId = event.item.attributes.elementid.value;
                            let newPosition = event.newIndex + 1;
                            if (
                                event.item.classList.contains("folder-container")
                            ) {
                                moveFolder(itemId, newPosition);
                            } else {
                                moveFile(itemId, newPosition);
                            }
                        },
                    });
                });
                let containers2 = document.querySelectorAll(
                    ".social-folder-content"
                );
                Object.values(containers2).map((element, index) => {
                    new Sortable(element, {
                        animation: 150,
                        draggable: '.social-item-container',
                        ghostClass: "ghost",
                        fallbackOnBody: true,
                        swapThreshold: 0.65,
                        onEnd: (event) => {

                            let itemId = event.item.attributes.elementid.value;
                            let newPosition = event.newIndex + 1;
                            if (event.item.attributes.class.value.includes('file')) {
                                moveFile(itemId, newPosition);
                            } else {
                                moveBloc(itemId, newPosition);
                            }
                        },
                    });
                });
            }
        });
    }, []);

    useEffect(() => {
        setGeneratedUnionSection(generateUnionSection());
    }, [unionInfos]);

    return (
        <div className="documents-container">
            <div className="sections-container">
                {Object.values(socialDocuments).length > 0
                    ? socialDocuments?.map((section, key) => {
                        return (
                            <SocialSection
                                key={key}
                                section={section}
                                type="social"
                                isEdit={isEdit}
                            />
                        );
                    })
                    : ""}
                {/*{Object.values(cseInfos).length > 0 ? (*/}
                {/*    <SocialSection section={cseInfos[0]} type="cse" />*/}
                {/*) : (*/}
                {/*    ""*/}
                {/*)}*/}

                {/*<SocialSection*/}
                {/*    section={generatedUnionSection}*/}
                {/*    type="union"*/}
                {/*    // label="Communications syndicales"*/}
                {/*    // categoryLabel="Syndicats"*/}
                {/*/>*/}
                {/* {Object.values(unionInfos).length > 0
                    ? unionInfos?.map((unionInfo, key) => {
                          return (
                              <SocialSection key={key} section={unionInfo} />
                          );
                      })
                    : ""} */}
                {/*{Object.values(socialInfos).length > 0*/}
                {/*    ? socialInfos?.map((socialInfo, key) => {*/}
                {/*          return (*/}
                {/*              <SocialSection*/}
                {/*                  type="union"*/}
                {/*                  key={key}*/}
                {/*                  section={socialInfo}*/}
                {/*              />*/}
                {/*          );*/}
                {/*      })*/}
                {/*    : ""}*/}
            </div>
        </div>
    );
};

export default SocialList;
