import React, {Fragment, useEffect} from "react";
import {Switch, Route} from "react-router";
import {isGedManager, isUnionManager, isSocialManager} from "utils/Utils";
import BackendHome from "components/Backend/BackendHome";
import Header from "components/common/Header/Header";
import AbsenceContainer from "components/Backend/AbsenceContainer/AbsenceContainer";
import PeriodContainer from "components/Backend/PeriodContainer/PeriodContainer";
import Remote from "components/Backend/Remote/Remote";
import CRAContainer from "components/Backend/CRAContainer/CRAContainer";
import CRAContainerMobile from "components/Backend/CRAContainer/CRAContainerMobile";
import CRADisplay from "components/Backend/CRADisplay/CRADisplay";
import TeamPlanningContainer from "components/Backend/TeamPlanningContainer/TeamPlanningContainer";
import ExtraActivitiesRequestsContainer
    from "components/Backend/ExtraActivitiesRequestsContainer/ExtraActivitiesRequestsContainer";
import DocumentContainer from "components/Backend/DocumentContainer/DocumentContainer";
import UnionHome from "components/DocumentContainer/UnionHome";
import UnionDocuments from "components/DocumentContainer/UnionDocuments";
import * as loadDynamicScript from "utils/LoadDynamicScript.js";
import moment from "moment";
import "moment/locale/fr";
import PageTransition from "./common/PageTransition";
import AbsenceContainerMobile from "./Backend/AbsenceContainer/AbsenceContainerMobile";
import ExtraActivitiesRequestsContainerMobile
    from "./Backend/ExtraActivitiesRequestsContainer/ExtraActivitiesRequestsContainerMobile";
import {useSelector} from "react-redux";
import TeamPlanningPage from "./Backend/TeamPlanningContainer/TeamPlanningPage";
import TeamPlanningMobile from "./mobile/TeamPlanningMobile/TeamPlanningMobile";

const BackendRoutes = (props) => {
    const {me} = props;
    const isMobile = useSelector((state) => state.common.isMobile)

    return (
        <Fragment>
            {!isMobile && <Header backend={true}/>}
            <Switch>
                {me.hasRole("ROLE_ABSENCE_SHOW") && (
                    <Route path="/backend/absences">
                        <PageTransition backgroundColor="#a51160" text="Validation d'absences" icon={"fa-check"}>
                            {!isMobile ? <AbsenceContainer/> : <AbsenceContainerMobile/>}
                        </PageTransition>
                    </Route>
                )}

                {me.hasRole("ROLE_PAY_MANAGER") && !isMobile && (
                    <Route
                        path="/backend/remote-work"
                        component={() => <Remote/>}
                    />
                )}
                {me.hasRole("ROLE_BILLING_SUPERVISOR") && !isMobile && (
                    <Route
                        path="/backend/periods"
                        component={() => <PeriodContainer/>}
                    />
                )}
                {me.hasRole("ROLE_ACTIVITY_SHOW") && (
                    <Route path="/backend/activity">
                        <PageTransition backgroundColor="#007188" text="Validation CRA" icon={"fa-calendar-check"}>
                            {!isMobile ? <CRAContainer/> : <CRAContainerMobile/>}
                        </PageTransition>
                    </Route>
                )}
                {me.hasRole("ROLE_CRA_SHOW") && (
                    <Route
                        path="/backend/cra/:monthId/:month/:year/:userMatricule"
                        component={() => <CRADisplay/>}
                    />
                )}

                {me.hasRole("ROLE_ABSENCE_ACCEPT_FIRST_LEVEL") && (
                    <Route path="/backend/team-planning/:month?/:year?">
                        <PageTransition backgroundColor="#e7e000" text="Planning d'équipe" icon={"fa-calendar-alt"}
                                        textColor="#17313a">
                            <TeamPlanningContainer />
                        </PageTransition>
                    </Route>
                )}

                {me.hasRole("ROLE_ABSENCE_ACCEPT_FIRST_LEVEL") && (
                    <Route path="/backend/team-planning-by-week/:year?/:week?">
                        <PageTransition backgroundColor="#e7e000" text="Planning d'équipe" icon={"fa-calendar-alt"}
                                        textColor="#17313a">
                            <TeamPlanningMobile />
                        </PageTransition>
                    </Route>
                )}

                {isGedManager(me) && !isMobile && (
                    <Route
                        path="/backend/documents/ged"
                        component={() => <DocumentContainer/>}
                    />
                )}

                {isGedManager(me) && !isMobile && (
                    <Route
                        path="/backend/social/ged"
                        component={() => <DocumentContainer isSocial={true}/>}
                    />
                )}

                {isUnionManager(me) && !isMobile && (
                    <Route
                        path="/backend/documents/syndicats/:id"
                        component={() => (
                            <div id="union-container" className="backend">
                                <UnionDocuments me={me} isEdit={true}/>
                            </div>
                        )}
                    />
                )}

                {isUnionManager(me) && !isMobile && (
                    <Route
                        path="/backend/documents/syndicats"
                        component={() => (
                            <div id="union-container" className="backend">
                                <UnionHome me={me} isEdit={true}/>
                            </div>
                        )}
                    />
                )}

                <Route path="/backend/extra-activities">
                    <PageTransition backgroundColor="#fdbc32" text="Validation demande d'activité complémentaire" icon={"fa-calendar-star"}
                                    textColor="#fff">
                        {!isMobile ? <ExtraActivitiesRequestsContainer me={me}/> : <ExtraActivitiesRequestsContainerMobile />}
                    </PageTransition>
                </Route>

                <Route path="/backend/" component={() => <BackendHome/>}/>
            </Switch>
        </Fragment>
    );
};

export default BackendRoutes;
