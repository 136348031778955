class Contact {
    phoneNumber;
    email;

    constructor(json) {
        this.phoneNumber = json.phone_number;
        this.email = json.email;
    }
}

export default Contact;
