import React, {useState} from "react";
import DoctorItem from "components/DocumentContainer/Social/DoctorItem";
import InsuranceItem from "components/DocumentContainer/Social/InsuranceItem";
import SsctItem from "components/DocumentContainer/Social/SsctItem";
import PsychoItem from "components/DocumentContainer/Social/PsychoItem";
import HarassmentItem from "components/DocumentContainer/Social/HarassmentItem";
import LinkItem from "components/DocumentContainer/Social/LinkItem";
import FileRow from "components/DocumentContainer/FileRow";
import CseItem from "components/DocumentContainer/Social/CseItem";
import SocialRowItem from "components/DocumentContainer/Social/SocialRowItem";
import File from '../../../entities/File'
import "./SocialItem.scss";

const SocialItem = (props) => {
    const {item, isEdit} = props;

    return (item instanceof File) ? (
        <div className="file-item-container social-item-container" elementid={item.id}>
            <FileRow file={item} visible={true} isEdit={isEdit}/>
        </div>
    ) : ((item.urlOnly ? (<CseItem file={item} visible={true}/>) : <SocialRowItem item={item} isEdit={isEdit}/>))
};

export default SocialItem;
