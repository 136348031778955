import React, {Fragment, useState} from "react";
import "./HarassmentItem.scss";
import "./SsctItem.scss";
import "./InsuranceItem.scss";
import "./CseItem.scss";
import {useDispatch} from "react-redux";
import {changeOverlayContent} from "events/OverlayEvents";
import * as DocumentActions from "actions/Document";
import ConfirmLightbox from "components/common/ConfirmLightbox";
import Dropdown from "components/common/Dropdown";
import BlocContactLightbox from "../../Backend/DocumentContainer/BlocContactLightbox";

const SocialRowItem = (props) => {
    const dispatch = useDispatch();
    const {item, isEdit} = props;
    const decideStructure = () => {
        if (item.names && ((item.mails && item.phones && ((item.mails.length > 0 && item.phones.length === 0) || (item.phones.length > 0 && item.mails.length === 0))))) {
            return 'big-blocks'
        } else if ((item.names && (item.mails || item.phones)) || ((item.mails && item.phones))) {
            return 'one-liners'
        } else if (item.urlOnly) {
            return 'url-only'
        } else {
            return 'big-blocks'
        }
    }
    const [rowType, setRowType] = useState(decideStructure());
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="social-item-container ssct-item harassment-item" elementid={item.id}>
            {isEdit && (
                <Dropdown
                    isDropdownVisible={isDropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                >
                    <ul className="dropdown-list">
                        <>
                            <li onClick={() => {
                                setDropdownVisible(false)
                                changeOverlayContent(
                                    <BlocContactLightbox
                                        mode="update"
                                        blocContact={item}
                                    />
                                )
                            }}>Modifier ce bloc
                            </li>
                            <li onClick={() => {
                                setDropdownVisible(false)
                                changeOverlayContent(
                                    <ConfirmLightbox
                                        title={"Supprimer un bloc"}
                                        message={`Êtes-vous sûr de vouloir supprimer ce bloc ?`}
                                        handleSubmit={() =>
                                            dispatch(
                                                DocumentActions.deleteBloc(
                                                    item.id
                                                )
                                            ).then(() => {
                                                changeOverlayContent(null);
                                                dispatch(
                                                    DocumentActions.fetchSocialDocuments()
                                                );
                                                dispatch(
                                                    DocumentActions.fetchUnions()
                                                );
                                            })
                                        }
                                    />
                                )
                            }}>Supprimer ce bloc
                            </li>
                        </>
                    </ul>
                </Dropdown>
            )}
            {item.title && <div className="ssct-title">{item.title}</div>}
            {rowType === "one-liners" && item.names && (item.names.map((name, key) => {
                return <div key={key}>
                    <div className="harassment-title">
                        {name}
                    </div>
                    <div className="harassment-contact">
                        {item.phones && item.phones[key] &&
                            <div className="harassment-phones">
                                <i className="fal fa-phone"></i>
                                <div className="harassment-phones-content">
                                    {item.phones[key]}
                                </div>
                            </div>
                        }
                        {/*{item.mails && item.phones && <div></div>}*/}
                        {item.mails && item.mails[key] &&
                            <div className="harassment-emails">
                                <i className="fal fa-envelope-open"></i>
                                <div className="harassment-emails-content">
                                    <a
                                        className="harassment-email"
                                        href={`mailto:${item.mails[key]}`}
                                        key={key}
                                    >
                                        {item.mails[key]}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    {(item.address || item.url) && <div className="insurance-item">
                        {item.url && <div className="url-item">
                            <div className="url-picto">
                                <i className="fal fa-globe"></i>
                            </div>
                            <div className="url-content">
                                <div className="url-content-label">
                                    {item.urlLabel}
                                </div>
                                <a href={item.url} target="_blank">
                                    {item.url}
                                </a>
                            </div>
                        </div>}
                        {item.address && <div className="insurance-address">
                            <i className="fal fa-map-marker"></i>
                            {item.address}
                        </div>}
                    </div>}
                </div>
            }))}
            {rowType === "one-liners" && !item.names && (item.phones.map((phone, key) => {
                return <div key={key}>
                    <div className="harassment-contact">
                        {item.phones && item.phones[key] &&
                            <div className="harassment-phones">
                                <i className="fal fa-phone"></i>
                                <div className="harassment-phones-content">
                                    {item.phones[key]}
                                </div>
                            </div>
                        }
                        {/*{item.mails && item.phones && <div></div>}*/}
                        {item.mails && item.mails[key] &&
                            <div className="harassment-emails">
                                <i className="fal fa-envelope-open"></i>
                                <div className="harassment-emails-content">
                                    <a
                                        className="harassment-email"
                                        href={`mailto:${item.mails[key]}`}
                                        key={key}
                                    >
                                        {item.mails[key]}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>

                    {(item.address || item.url) && <div className="insurance-item">
                        {item.url && <div className="url-item">
                            <div className="url-picto">
                                <i className="fal fa-globe"></i>
                            </div>
                            <div className="url-content">
                                <div className="url-content-label">
                                    {item.urlLabel}
                                </div>
                                <a href={item.url} target="_blank">
                                    {item.url}
                                </a>
                            </div>
                        </div>}
                        {item.address && <div className="insurance-address">
                            <i className="fal fa-map-marker"></i>
                            {item.address}
                        </div>}
                    </div>}
                </div>
            }))}
            {rowType === 'big-blocks' && <div className="ssct-contact">
                <div className="ssct-persons">
                    {item.names && item.names.map((person, key) => {
                        return <div key={key}>{person}</div>;
                    })}
                </div>
                <div className="separator">
                    <div className="triangle"></div>
                </div>
                {item.mails && item.mails.length > 0 && <div className="ssct-emails">
                    <i className="fal fa-envelope-open"></i>
                    <div className="ssct-emails-content">
                        {item.mails && item.mails.map((email, key) => {
                            return (
                                <a href={`mailto:${email}`} key={key}>
                                    {email}
                                </a>
                            );
                        })}
                    </div>
                </div>}
                {item.phones && item.phones.length > 0 && <div className="ssct-emails">
                    <i className="fal fa-phone"></i>
                    <div className="ssct-emails-content">
                        {item.phones && item.phones.map((email, key) => {
                            return (
                                <div className="harassment-phones-content" key={key}>
                                    {item.phones[key]}
                                </div>
                            );
                        })}
                    </div>
                </div>}
                {(item.address || item.url) && <div className="insurance-item">
                    {item.url && <div className="url-item">
                        <div className="url-picto">
                            <i className="fal fa-globe"></i>
                        </div>
                        <div className="url-content">
                            <div className="url-content-label">
                                {item.urlLabel}
                            </div>
                            <a href={item.url} target="_blank">
                                {item.url}
                            </a>
                        </div>
                    </div>}
                    {item.address && <div className="insurance-address">
                        <i className="fal fa-map-marker"></i>
                        {item.address}
                    </div>}
                </div>}
            </div>}
            {rowType === 'url-only' && <div className="cse-item">
                <a className="cse-item-link" href={item.url} target="_blank">
                    <i className="fal fa-globe"></i>
                    <span className="cse-item-label">{item.urlLabel}</span>
                    {/*<img className="cse-item-logo" src={item.iconUrl}/>*/}
                </a>
            </div>}
        </div>
    );
};

export default SocialRowItem;
