import Doctor from "./Doctor";
import Contact from "./Contact";
import Address from "./Address";

class CheckupCenter {
    address;
    agencyCode;
    cityName;
    commentary;
    contact;
    doctors;
    fullname;
    name;

    constructor(json) {
        this.address = new Address(json.address);
        this.agencyCode = json.agency_code;
        this.cityName = json.city_name;
        this.commentary = json.commentary;
        this.contact = new Contact(json.contact);
        let doctorsArray = [];
        json.doctors.map((doctor) => {
            if (doctor.main_doctor) {
                doctorsArray.push(new Doctor(doctor));
            }
        });
        this.doctors = doctorsArray;
        this.fullname = json.full_name;
        this.name = json.name;
    }

    get mainDoctor() {
        return this.doctors[0];
    }
}

export default CheckupCenter;
