import React from "react";

import "./BackendHome.scss";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isGedManager, isUnionManager} from "utils/Utils";

const BackendHome = () => {
    const me = useSelector((state) => state.user.me);

    return (
        <div className="backend-container">
            <div className="backend-container-header">
                Mon espace d'administration
            </div>

            <div className="backend-container-links">
                {(me.hasRole("ROLE_MANAGER") ||
                    me.hasRole("ROLE_ABSENCE_SHOW") ||
                    me.hasRole("ROLE_ACTIVITY_SHOW") ||
                    me.hasRole("ROLE_BILLING_SUPERVISOR")) && (
                    <div className="backend-container-links-list">
                        <div className="backend-container-links-list-title">
                            <i className="backend-action-icon fal fa-calendar-check"></i>{" "}
                            Gestion des activités/temps
                        </div>
                        <div className="backend-container-links-list-content">
                            {me.hasRole("ROLE_MANAGER") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/team-planning"
                                >
                                    Voir le planning d'équipe
                                </Link>
                            )}
                            {me.hasRole("ROLE_ABSENCE_SHOW") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/absences"
                                >
                                    Gérer les absences
                                </Link>
                            )}
                            {me.hasRole("ROLE_ACTIVITY_SHOW") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/activity"
                                >
                                    Gérer les activités
                                </Link>
                            )}
                            {me.hasRole("ROLE_MANAGER") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/extra-activities"
                                >
                                    Gérer les demandes d'activité complémentaire
                                </Link>
                            )}
                            {me.hasRole("ROLE_BILLING_SUPERVISOR") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/periods"
                                >
                                    Gérer les clôtures
                                </Link>
                            )}
                            {me.hasRole("ROLE_PAY_MANAGER") && (
                                <Link
                                    className="backend-link"
                                    to="/backend/remote-work"
                                >
                                    Lister les fichiers télétravail
                                </Link>
                            )}
                        </div>
                    </div>
                )}

                {(isGedManager(me) || isUnionManager(me)) && (
                    <div className="backend-container-links-list">
                        <div className="backend-container-links-list-title">
                            <i className="backend-action-icon fal fa-file-contract"></i>{" "}
                            Gestion des ressources
                        </div>
                        <div className="backend-container-links-list-content">
                            {isGedManager(me) && (
                                <Link
                                    className="backend-link"
                                    to="/backend/documents/ged"
                                >
                                    Gérer les documents
                                </Link>
                            )}
                            {isGedManager(me) && (
                                <Link
                                    className="backend-link"
                                    to="/backend/social/ged"
                                >
                                    Gérer les informations sociales
                                </Link>
                            )}
                            {isUnionManager(me) && (
                                <Link
                                    className="backend-link"
                                    to="/backend/documents/syndicats"
                                >
                                    Gérer les communications syndicales
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BackendHome;
