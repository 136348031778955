import File from "entities/File";
import Bloc from "entities/Bloc";
import CheckupCenter from "./Checkup/CheckupCenter";

const CHECKUP_TAG = 'checkup'

class Folder {
    id;
    label;
    priority;
    files;
    blocs;
    checkupCenterInformation;
    contentTag;

    constructor(json) {
        this.id = json.id;
        this.label = json.label;
        this.priority = json.priority;
        let filesArray = [];
        json.files.map((file) => {
            filesArray.push(new File(file));
        });
        this.files = filesArray;
        let blocsArray = [];
        json.blocs.map((bloc) => {
            blocsArray.push(new Bloc(bloc));
        });
        this.blocs = blocsArray;
        this.position = json.priority;
        let centersArray = [];
        if (json.content_tag === CHECKUP_TAG) {
            json.checkup_center_information.map((item) => {
                centersArray.push(new CheckupCenter(item))
            })
            this.checkupCenterInformation = centersArray;
        }
        this.contentTag = json.content_tag;
    }

    get items() {
        let items = [];
        this.files.forEach((file) => {
            items.push(file);
        })
        this.blocs.forEach((bloc) => {
            items.push(bloc);
        })
        items.sort((a, b) => a.position - b.position);
        return items
    }

    get isCheckupFolder() {
        return this.contentTag === CHECKUP_TAG;
    }
}

export default Folder;
