class Address {
    address1;
    address2;
    address3;
    city;
    country;
    zipCode;

    constructor(json) {
        this.address1 = json.address1;
        this.address2 = json.address2;
        this.address3 = json.address3;
        this.city = json.city;
        this.country = json.country;
        this.zipCode = json.zip_code;
    }

    get fullAddress() {
        let fullAddress = this.address1;
        if (this.address2) {
            fullAddress += ' • ' + this.address2;
        }
        if (this.address3) {
            fullAddress += ' • ' + this.address3;
        }
        fullAddress += ' • ' + this.zipCode + ' ' + this.city;
        return fullAddress;
    }
}

export default Address;
