import React, {useState} from "react";
import "./DoctorItem.scss";

const DoctorItem = (props) => {
    const {item} = props;

    return (
        <div className="doctor-item harassment-item">
            <div className="doctor-place">{item.name}</div>
            {item.contact.phoneNumber && <div className="doctor-contact">
                <div className="doctor-phones">
                    <i className="fal fa-phone"></i>
                    <div className="doctor-phones-content">
                        {item.contact.phoneNumber && <div>{item.contact.phoneNumber}</div>}
                    </div>
                </div>
                <div className="doctor-emails">
                    <i className="fal fa-envelope-open"></i>
                    <div className="doctor-emails-content">
                        {item.contact.email && <a
                            className="doctor-email"
                            href={`mailto:${item.contact.email}`}
                        >
                            {item.contact.email}
                        </a>}
                    </div>
                </div>
            </div>}
            {item.mainDoctor && <>
                <div>
                    <span className="doctor-title">Médécin : </span>
                    {item.mainDoctor.fullname}
                </div>
                <div className="doctor-contact">
                    <div className="doctor-phones">
                        <i className="fal fa-phone"></i>
                        <div className="doctor-phones-content">
                            {item.mainDoctor.contact.phoneNumber && <div>{item.mainDoctor.contact.phoneNumber}</div>
                            }
                            {
                                item.mainDoctor.assistants[0].contact.phoneNumber && item.mainDoctor.assistants.map((assistant, key) => {
                                    return <div key={key}>{assistant.contact.phoneNumber}</div>;
                                })
                            }
                        </div>
                    </div>
                    <div className="doctor-emails">
                        <i className="fal fa-envelope-open"></i>
                        <div className="doctor-emails-content">
                            {item.mainDoctor.contact.email && <div>{item.mainDoctor.contact.email}</div>}
                            {item.mainDoctor.assistants[0].contact.email && item.mainDoctor.assistants.map((assistant, key) => {
                                return (
                                    <a
                                        className="doctor-email"
                                        href={`mailto:${assistant.contact.email}`}
                                        key={key}
                                    >
                                        {assistant.contact.email}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>}
            <div className="doctor-address">
                <i className="fal fa-map-marker"></i>
                {item.address.fullAddress}
            </div>
        </div>
    );
};

export default DoctorItem;
