import React, {useState} from "react";
import { useDispatch } from "react-redux";
import SocialItem from "components/DocumentContainer/Social/SocialItem";
import {changeOverlayContent} from "events/OverlayEvents";
import Dropdown from "components/common/Dropdown";
import "./SocialFolder.scss";
import DoctorItem from "./DoctorItem";
import BlocContactLightbox from "../../Backend/DocumentContainer/BlocContactLightbox";
import FileDepositLightbox from "../../Backend/DocumentContainer/FileDepositLightbox";
import ItemLightbox from "../../Backend/DocumentContainer/ItemLightbox";
import ConfirmLightbox from "../../common/ConfirmLightbox";
import * as DocumentActions from "actions/Document";

const SocialFolder = (props) => {
    const {folder, type, isEdit} = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div
            className={
                "folder-container first-level-dnd" +
                (type === "union" ? " union-folder-container" : "") +
                (open ? "" : " closed")
            }
            elementid={folder.id}
        >
            <div className="social-folder-row" onClick={() => setOpen(!open)}>
                <div className="folder-label">{folder.label}</div>
                {isEdit && folder.contentTag !== "union" && (
                    <Dropdown
                        isDropdownVisible={isDropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                    >
                        <ul className="dropdown-list">
                            <>
                                <li onClick={() => {
                                    setDropdownVisible(false)
                                    changeOverlayContent(
                                        <ItemLightbox
                                            mode="update"
                                            folderId={folder.id}
                                            itemLabel={folder.label}
                                        />
                                    );
                                }}>Renommer le dossier
                                </li>
                                <li onClick={() => {
                                    setDropdownVisible(false)
                                    changeOverlayContent(
                                        <FileDepositLightbox
                                            folderId={folder.id}
                                        />
                                    );
                                }}>Ajouter un fichier au dossier
                                </li>
                                <li onClick={() => {
                                    setDropdownVisible(false)
                                    changeOverlayContent(
                                        <BlocContactLightbox
                                            mode="create"
                                            folderId={folder.id}
                                        />
                                    )
                                }}>Ajouter un bloc contact
                                </li>
                                <li
                                className={
                                    (folder.files.length > 0 || folder.blocs.length > 0 || folder.contentTag) ? "disabled" : ""
                                }
                                onClick={() => {
                                    if (folder.files.length === 0 && folder.blocs.length === 0 && !folder.contentTag) {
                                        setDropdownVisible(false);
                                        changeOverlayContent(
                                            <ConfirmLightbox
                                                title={"Supprimer un dossier"}
                                                message={`Êtes-vous sûr de vouloir supprimer le dossier "${folder.label}" ?`}
                                                handleSubmit={() =>
                                                    dispatch(
                                                        DocumentActions.deleteFolder(
                                                            folder.id
                                                        )
                                                    ).then(() => {
                                                        changeOverlayContent(
                                                            null
                                                        );
                                                        dispatch(
                                                            DocumentActions.fetchSocialDocuments()
                                                        );
                                                        dispatch(
                                                            DocumentActions.fetchUnions()
                                                        );
                                                    })
                                                }
                                            />
                                        );
                                    }
                                }}
                            >
                                Supprimer le dossier
                            </li>
                            </>
                        </ul>
                    </Dropdown>
                )}
                <i
                    className={
                        "far fa-chevron-right expand-icon expand-icon-before" +
                        (open ? " rotated-icon" : "")
                    }
                ></i>
            </div>
            <div className={(open ? "" : "hidden ") + 'social-folder-content'}>
                {folder.isCheckupFolder === true && folder.checkupCenterInformation.map((item, key) => {
                    return <DoctorItem item={item} key={key}/>
                })}
                {folder.items instanceof Array &&
                    folder.items.map((item, key) => {
                        return <SocialItem item={item} key={key} isEdit={isEdit}/>;
                    })}
                {/*{folder.files instanceof Array &&*/}
                {/*    folder.files.map((item, key) => {*/}
                {/*        return <SocialItem item={item} key={key} />;*/}
                {/*    })}*/}
            </div>
        </div>
    );
};

export default SocialFolder;
