import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import SocialFolder from "components/DocumentContainer/Social/SocialFolder";
import {changeOverlayContent} from "events/OverlayEvents";
import FileDepositLightbox from "components/Backend/DocumentContainer/FileDepositLightbox";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import ConfirmLightbox from "components/common/ConfirmLightbox";
import Dropdown from "components/common/Dropdown";
import * as DocumentActions from "actions/Document";
import "./SocialCategory.scss";
import FileRow from "../FileRow";

const SocialCategory = (props) => {
    const dispatch = useDispatch();
    const {category, type, isEdit} = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const canDeleteCategory = () => {
        let allowed = true
        if (category.files !== undefined && category.files.length > 0) {
            allowed = false
        }
        if (category.folders !== undefined && category.folders.length > 0) {

            allowed = false
        }

        return allowed
    }

    return (
        <div className="category-container">
            <div className="category-label">
                {category.label}
                {isEdit && (
                    <Dropdown
                        isDropdownVisible={isDropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                    >
                        <ul className="dropdown-list">
                            {type !== "union" ? (
                                <>
                                    <li
                                        onClick={() => {
                                            setDropdownVisible(false);
                                            changeOverlayContent(
                                                <FileDepositLightbox
                                                    categoryId={category.id}
                                                />
                                            );
                                        }}
                                    >
                                        Ajouter un fichier à cette catégorie
                                    </li>
                                    <li
                                        onClick={() => {
                                            setDropdownVisible(false);
                                            changeOverlayContent(
                                                <ItemLightbox
                                                    categoryId={category.id}
                                                    mode="create"
                                                />
                                            );
                                        }}
                                    >
                                        Ajouter un dossier à cette catégorie
                                    </li>
                                    <li
                                        className={
                                            !canDeleteCategory()
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={() => {
                                            if (canDeleteCategory()) {
                                                setDropdownVisible(false);
                                                changeOverlayContent(
                                                    <ConfirmLightbox
                                                        title={
                                                            "Supprimer une catégorie"
                                                        }
                                                        message={`Êtes-vous sûr de vouloir supprimer la catégorie "${category.title}" ?`}
                                                        handleSubmit={() =>
                                                            dispatch(
                                                                DocumentActions.deleteCategory(
                                                                    category.id
                                                                )
                                                            ).then(() => {
                                                                changeOverlayContent(
                                                                    null
                                                                );
                                                                dispatch(
                                                                    DocumentActions.fetchSocialDocuments()
                                                                );
                                                                dispatch(
                                                                    DocumentActions.fetchUnions()
                                                                );
                                                            })
                                                        }
                                                    />
                                                );
                                            }
                                        }}
                                    >
                                        Supprimer cette catégorie
                                    </li>
                                </>
                            ) : (
                                <li
                                    onClick={() => {
                                        setDropdownVisible(false);
                                        changeOverlayContent(
                                            <FileDepositLightbox
                                                unionId={0}
                                                type={type}
                                            />
                                        );
                                    }}
                                >
                                    Ajouter un fichier
                                </li>
                            )}
                        </ul>
                    </Dropdown>
                )}
            </div>
            {category.folders instanceof Array &&
                category.folders.map((folder, key) => {
                    return (
                        <SocialFolder folder={folder} key={key} type={type} isEdit={isEdit}/>
                    );
                })}
            {category.files instanceof Array &&
                category.files.map((file, key) => {
                    return (
                        <div className="file-item-container" key={key}>
                            <FileRow file={file} visible={true} isEdit={isEdit}/>
                        </div>
                    );
                })}
        </div>
    );
};

export default SocialCategory;
