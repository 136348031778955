import React, { useState } from "react";
import Lightbox from "components/common/Lightbox";
import { changeOverlayContent } from "events/OverlayEvents";
import Button from "../TimesheetContainer/Forms/Event/Button";

const ConfirmLightbox = (props) => {
    const { title, handleSubmit, message } = props;
    const [submitting, setSubmitting] = useState(false);

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{ textTransform: "uppercase" }}
                >
                    {title}
                </div>

                <div className="confirm-message">{message}</div>

                <div className="lb-confirm-send-actions">
                    <div
                        onClick={() => changeOverlayContent(null)}
                        className="lb-confirm-send-cancel"
                    >
                        Non
                    </div>
                    <Button
                    className="lb-confirm-send-confirm"
                    onClick={handleSubmit}
                    disabled={submitting}
                    text={"Oui"}
                    processable={true}
                    isProcessing={submitting}
                />
                    {/*<div*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    className={*/}
                    {/*        "lb-confirm-send-confirm" +*/}
                    {/*        (submitting ? " disabled-btn" : "")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    Oui*/}
                    {/*</div>*/}
                </div>
            </div>
        </Lightbox>
    );
};

export default ConfirmLightbox;
