import Contact from "./Contact";
import Assistant from "./Assistant";

class Doctor {
    assistants;
    contact;
    firstname;
    lastname;
    title;
    mainDoctor;

    constructor(json) {
        let assistantsArray = [];
        this.assistants = json.assistants.map(item => {
            assistantsArray.push(new Assistant(item));
        })
        this.assistants = assistantsArray;
        this.contact = new Contact(json.contact);
        this.firstname = json.firstname;
        this.lastname = json.lastname;
        this.title = json.title;
        this.mainDoctor = json.main_doctor;
    }

    get fullname() {
        return "Dr " + this.firstname + " " + this.lastname;
    }
}

export default Doctor;
