import React, { useState } from "react";
import "./CseItem.scss";

const CseItem = (props) => {
    const { file } = props;

    return (
        <div className="cse-item" elementid={file.id}>
            <a className="cse-item-link" href={file.url} target="_blank">
                <i className="fal fa-globe"></i>
                <span className="cse-item-label">{file.urlLabel}</span>
                <img className="cse-item-logo" src={file.iconUrl} />
            </a>
        </div>
    );
};

export default CseItem;
